import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AdminBaseComponent } from '@ih/admin-base';
import { fadeInOutAndHeightM3 } from '@ih/animations';
import { ConfirmDialogService } from '@ih/confirm';
import { InProgressDirective } from '@ih/directives';
import { InfoPanelComponent } from '@ih/info-panel';
import { BaseClientConfig, StripeAccountStatus } from '@ih/interfaces';
import { AuthService, ConfigService, LazySnackBarService } from '@ih/services';
import { wrapSvg } from '@ih/utilities';
import { mdiOpenInNew } from '@mdi/js';
import { Subject, catchError, map, startWith, switchMap, tap } from 'rxjs';

interface SimplifiedStripeConnectStatus {
  accountId: string;
  email: string;
  chargesEnabled: boolean;
  payoutsEnabled: boolean;
  requirements: boolean;
  requirementsDeadline?: Date;
  detailsSubmitted: boolean;
  warnings: string[];
  errors: string[];
}

@Component({
  selector: 'ih-merchant-accounts',
  standalone: true,
  imports: [
    InProgressDirective,
    InfoPanelComponent,
    DatePipe,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    AdminBaseComponent
  ],
  templateUrl: './merchant-accounts.component.html',
  styleUrl: './merchant-accounts.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOutAndHeightM3]
})
export class MerchantAccountsComponent {
  private http = inject(HttpClient);
  private snackbar = inject(LazySnackBarService);
  private confirmDialog = inject(ConfirmDialogService);
  private config = inject(ConfigService<BaseClientConfig>);
  private auth = inject(AuthService);
  private router = inject(Router);

  private cancelSync$ = new Subject<void>();
  private refresh$ = new Subject<void>();

  stripeAccountId = toSignal(this.config.config$.pipe(map((config) => config.stripe.accountId)));

  syncronizingAccount = signal(false);
  gettingAccountLink = signal(false);
  disconnectingAccount = signal(false);
  accountStatus = toSignal(
    this.refresh$.pipe(
      startWith(null),
      switchMap(() =>
        this.http.get<StripeAccountStatus>('/api/stripe/connect/status').pipe(
          map((status) => {
            this.syncronizingAccount.set(true);
            this.http
              .post<StripeAccountStatus>('/api/stripe/connect/sync', {})
              .pipe(
                tap((updatedStatus) => {
                  this.syncronizingAccount.set(false);
                  // if the status has changed then refresh the account status
                  if (JSON.stringify(status) !== JSON.stringify(updatedStatus)) {
                    this.refresh$.next();
                  }
                }),
                catchError((err) => {
                  this.syncronizingAccount.set(false);
                  this.snackbar.open('Failed to sync account');
                  throw err;
                })
              )
              .subscribe();

            const simplifiedStatus: SimplifiedStripeConnectStatus = {
              accountId: status.accountId,
              email: status.email,
              chargesEnabled: status.chargesEnabled,
              payoutsEnabled: status.payoutsEnabled,
              requirements: status.requirements,
              requirementsDeadline: status.requirementsDeadline,
              detailsSubmitted: status.detailsSubmitted,
              warnings: [],
              errors: status.errors || []
            };

            return simplifiedStatus;
          }),
          catchError((err) => {
            this.snackbar.open('Failed to get account status');
            throw err;
          })
        )
      )
    )
  );

  constructor(registry: MatIconRegistry, sanitizer: DomSanitizer) {
    this.auth.isAuthenticated$.pipe(takeUntilDestroyed()).subscribe((isAuthenticated) => {
      if (!isAuthenticated) {
        console.debug('MerchantAccountsComponent: user is not authenticated. Navigating to home.');
        this.router.navigate(['/home']);
      }
    });

    registry.addSvgIconLiteral('open-in-new', sanitizer.bypassSecurityTrustHtml(wrapSvg(mdiOpenInNew)));
  }

  getAccountLink(): void {
    this.gettingAccountLink.set(true);
    this.syncronizingAccount.set(false);
    this.cancelSync$.next();
    this.http
      .post<string>(
        '/api/stripe/connect',
        {},
        {
          responseType: 'text' as 'json'
        }
      )
      .pipe(
        catchError((err) => {
          this.snackbar.open('Failed to get account link');
          this.gettingAccountLink.set(false);

          throw err;
        }),
        tap((link) => (window.location.href = link))
      )
      .subscribe();
  }

  async disconnectAccount(): Promise<void> {
    const dialog = await this.confirmDialog.open({
      title: 'Disconnect Account',
      message:
        'Are you sure you want to disconnect your Stripe account? This action cannot be undone, and will prevent new members from joining your paid channels',
      cancelText: 'Cancel',
      confirmText: 'Disconnect',
      confirmColor: 'warn'
    });

    dialog.afterClosed().subscribe((confirmed) => {
      if (!confirmed) {
        return;
      }

      this.disconnectingAccount.set(true);
      this.http
        .delete('/api/stripe/connect', {})
        .pipe(
          catchError((err) => {
            this.snackbar.open('Failed to disconnect account');
            this.disconnectingAccount.set(false);

            throw err;
          }),
          tap(() => {
            this.syncronizingAccount.set(false);
            this.cancelSync$.next();

            this.refresh$.next();
          })
        )
        .subscribe();
    });
  }
}
