<ih-admin-base
  editorTitle="Payment processors"
  editorIconPath="M7,15H9C9,16.08 10.37,17 12,17C13.63,17 15,16.08 15,15C15,13.9 13.96,13.5 11.76,12.97C9.64,12.44 7,11.78 7,9C7,7.21 8.47,5.69 10.5,5.18V3H13.5V5.18C15.53,5.69 17,7.21 17,9H15C15,7.92 13.63,7 12,7C10.37,7 9,7.92 9,9C9,10.1 10.04,10.5 12.24,11.03C14.36,11.56 17,12.22 17,15C17,16.79 15.53,18.31 13.5,18.82V21H10.5V18.82C8.47,18.31 7,16.79 7,15Z"
>
  <ng-container button-controls>
    <!-- no button for you -->
  </ng-container>

  <div class="payment-processors-container">
    <div class="section-block">
      <div class="stripe-logo-container fx-layout-row fx-layout-align-center">
        <img
          src="https://ih-cdn.ihub.app/img/stripe_wordmark_blurple_small.png"
          alt="Stripe logo"
          class="stripe-logo"
        />
      </div>

      @if (accountStatus(); as status) {
        @if (!status.accountId) {
          <div class="fx-layout-row fx-layout-align-center">
            <ih-info-panel @fadeInOutAndHeightM3 type="info" dismissable="false" class="info-panel">
              Connect a stripe account to start accepting payments.
            </ih-info-panel>
          </div>
        } @else {
          @if (status.email) {
            <div class="connected-account-email fx-layout-row fx-layout-align-center">
              Connected to: {{ status.email }}
            </div>
          }
          <div class="fx-layout-column fx-layout-align-center-center">
            @if (!status.detailsSubmitted) {
              <ih-info-panel @fadeInOutAndHeightM3 type="info" dismissable="false" class="info-panel">
                It appears that your account setup is not yet complete. Please click the "Complete account setup" button
                below to proceed with the setup process.
              </ih-info-panel>
            }
            @if (status.errors.length > 0) {
              <ih-info-panel @fadeInOutAndHeightM3 type="error" dismissable="false" class="info-panel">
                <span title> There are some problems with your stripe account: </span>

                @if (status.errors.length > 1) {
                  <ul>
                    @for (error of status.errors; track error) {
                      <li>{{ error }}</li>
                    }
                  </ul>
                } @else if (status.errors.length === 1) {
                  <div>{{ status.errors[0] }}</div>
                }
              </ih-info-panel>
            } @else {
              @if (!status.chargesEnabled || !status.payoutsEnabled) {
                <ih-info-panel @fadeInOutAndHeightM3 type="error" dismissable="false" class="info-panel">
                  <span title> Your stripe account is not fully enabled: </span>
                  <ul>
                    @if (!status.chargesEnabled) {
                      <li>Charges are not enabled</li>
                    }
                    @if (!status.payoutsEnabled) {
                      <li>Payouts are not enabled</li>
                    }
                  </ul>
                  Unfortunately, Stripe has not specified the reason for this. Please proceed to the Stripe dashboard
                  for more information.
                </ih-info-panel>
              }
            }
            @if (status.warnings.length > 0) {
              <ih-info-panel @fadeInOutAndHeightM3 type="warning" dismissable="false" class="info-panel">
                <span title> There are some warnings on your stripe account: </span>

                @if (status.warnings.length > 1) {
                  <ul>
                    @for (warning of status.warnings; track warning) {
                      <li>{{ warning }}</li>
                    }
                    @if (status.requirements) {
                      <li>
                        There is some additional documentation required to keep your stripe account enabled.
                        <br />
                        You must provide this documentation by: {{ status.requirementsDeadline | date: 'medium' }}
                      </li>
                    }
                  </ul>
                } @else if (status.warnings.length === 1) {}
              </ih-info-panel>
            }

            @if (
              status.detailsSubmitted &&
              status.chargesEnabled &&
              status.payoutsEnabled &&
              status.errors.length == 0 &&
              status.warnings.length == 0 &&
              !syncronizingAccount()
            ) {
              <ih-info-panel @fadeInOutAndHeightM3 type="success" dismissable="false" class="info-panel">
                Everything looks great. Please check back here periodically to see if Stripe requires any additional
                information.</ih-info-panel
              >
            }
          </div>

          <div class="fx-layout-row fx-layout-align-center-center">
            @if (syncronizingAccount()) {
              <ih-info-panel @fadeInOutAndHeightM3 type="info" dismissable="false" class="info-panel">
                Getting the latest updates from stripe. Please wait...
              </ih-info-panel>
            }
          </div>
        }

        <div class="fx-layout-row fx-layout-align-center-center">
          @if (!status.accountId) {
            <button
              mat-flat-button
              [inProgress]="gettingAccountLink()"
              class="btn-connect btn-connect-stripe"
              (click)="getAccountLink()"
            >
              Create/Connect account
            </button>
          } @else {
            @if (status.accountId) {
              @if (!status.detailsSubmitted) {
                <button
                  mat-flat-button
                  [inProgress]="gettingAccountLink()"
                  class="btn-connect complete-account-setup-btn"
                  (click)="getAccountLink()"
                >
                  Complete account setup
                </button>
              } @else {
                @if (
                  status.errors.length > 0 ||
                  status.warnings.length > 0 ||
                  !status.chargesEnabled ||
                  !status.payoutsEnabled
                ) {
                  <a
                    mat-flat-button
                    [inProgress]="gettingAccountLink()"
                    class="btn-connect complete-account-setup-btn"
                    target="_blank"
                    href="https://dashboard.stripe.com/b/{{ stripeAccountId() }}?=destination=/account/status"
                  >
                    Update account details
                    <mat-icon iconPositionEnd svgIcon="open-in-new"></mat-icon>
                  </a>
                }
              }
            }
          }
        </div>
        @if (status.accountId) {
          <div class="fx-layout-row fx-layout-align-center-center">
            <button
              mat-flat-button
              class="btn-connect"
              color="warn"
              [inProgress]="disconnectingAccount()"
              (click)="disconnectAccount()"
            >
              Disconnect
            </button>
          </div>
        }
      } @else {
        <div class="fx-layout-column fx-layout-align-center-center">
          <ih-info-panel @fadeInOutAndHeightM3 showIcon="false" dismissable="false" class="info-panel">
            Checking your account status...
          </ih-info-panel>
          <mat-spinner></mat-spinner>
        </div>
      }
    </div>
  </div>
</ih-admin-base>
